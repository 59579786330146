import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Seo from "../components/seo";
import { LocaleContext } from "../components/layout";

export default function About({ data }) {
  const { locale, url, isEinfacheSprache } = React.useContext(LocaleContext);
  const lang = isEinfacheSprache ? "ds" : locale;
  const page = data.markdownRemark;
  const img = getImage(page.frontmatter.cover);

  return (
    <>
      <Seo
        url={url}
        lang={locale}
        title={page.frontmatter.title}
        description={page.frontmatter.metaDescription}
        bodyClass="page-about"
      />
      <div className="about container">
        <div className="about__row row">
          <div className="about__column">
            <div className="about__column-wrapper">
              <h1 className="about__title">{page.frontmatter.title}</h1>
              <div
                className="about__description"
                dangerouslySetInnerHTML={{ __html: page.html }}
              />
            </div>
          </div>

          <div className="about__info about__column">
            <div className="about__column-wrapper about__column-wrapper--info">
              <GatsbyImage
                image={img}
                alt={page.frontmatter.title}
                className="about__image"
              />
              <div className="about__authors">
                {page.frontmatter.additionalInfo.map((item, i) => {
                  return (
                    <div className="about__author" key={`author-${i}`}>
                      <div className="about__author-names about__box">
                        {item.title}
                      </div>
                      <div
                        className="about__box"
                        dangerouslySetInnerHTML={{ __html: item.authors }}
                      ></div>
                      <div className="about__text">
                        <div
                          className="about__text-wrapper"
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query($locale: String!) {
    markdownRemark(
      frontmatter: { template: { eq: "about" } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        title
        metaDescription
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        additionalInfo {
          title
          authors
          text
        }
      }
    }
  }
`;
